body {
  font-family: 'Source Sans Pro', sans-serif;
}

a:hover {
  color: inherit;
}

label {
  margin-bottom: 0;
}

.invalid-feedback {
  display: block;
}

.block {
  display: block;
}

.blue-bg {
  background-color: rgb(21, 88, 191) !important;
}

.blue-text {
  color: rgb(21, 88, 191) !important;
}

.gray-text,
.gray-text:hover,
.gray-text:visited {
  color: #333;
  text-decoration: none;
}

.btn-lg, .btn-group-lg>.btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
}

.btn-sm, .btn-group-sm>.btn {
  padding: 0.15rem 0.3rem;
  font-size: 0.765625rem;
  line-height: 1.5;
}

.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.8);
  -webkit-animation: react-confirm-alert-fadeIn 0.1s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.1s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.1s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.1s 0.2s forwards;
  z-index: 99999999;
}

.ql-editor{
  min-height: 150px !important;
  max-height: 500px;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: scroll;
}

.react-tagsinput {
  border-radius: 0.25rem;
}

.react-tagsinput--focused {
  border-color: #6f9dca !important;
  box-shadow: 0 0 0 0.2rem rgba(50,93,136,0.25) !important;
}

.react-tagsinput.is-invalid {
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 0.2rem rgba(217,83,79,0.25);
}

.react-tagsinput-tag {
  color: #333 !important;
  border: 1px solid #ced4da !important;
  background-color: #f7f7f7 !important;
}

.react-autosuggest__suggestions-list {
  list-style-type: none !important;
  padding-left: 0px !important;
}

.react-autosuggest__suggestion > div {
  padding-left: 10px !important;
}

.md-card-header {
  padding: 20px;
  border-bottom: 1px solid #d2d2d2;
}

.role-banner {
  background-color: #ffc107;
  text-align: center;
  text-transform: uppercase;
  padding: 15px 0;
  font-size: 2rem;
  font-weight: bold;
}
